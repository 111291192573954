import { uuid } from 'vue-uuid'

import AddressEvents from "./AddressEvents.js";
import EmployeeEvents from "./EmployeeEvents.js";
import UserEvents from "@/domain/model/user/UserEvents.js";
import ClientEvents from "@/domain/model/client/ClientEvents.js";
import CompanyEvents from "../company/CompanyEvents.js";
import AdminEvents from "../admin/AdminEvents.js";
import CredentialEvents from "./CredentialEvents.js";

import RcRequestEvent from "./RcRequestEvent.js";
import RcEventListener from "./RcEventListener.js";

import ScheduleEvents from "@/domain/model/schedule/ScheduleEvents.js";
import InspectionEvents from "@/domain/model/inspection/InspectionEvents.js";
import VehicleEvents from "@/domain/model/vehicle/VehicleEvents.js";
import CardEvents from "@/domain/model/invoice/CardEvents.js";
import InvoiceEvents from "@/domain/model/invoice/InvoiceEvents.js";
import TimesheetEvents from "@/domain/model/timesheet/TimesheetEvents.js";
import AppEvents from "@/domain/model/app/AppEvents.js";
import CheckInEvents from "@/domain/model/checkin/CheckInEvents.js";
import CheckListEvents from "@/domain/model/checklist/CheckListEvents.js";

import Company from "@/domain/model/company/Company.js"
//import Employee from "@/domain/model/employee/Employee.js"

import ConstUtils from '@/utils/ConstUtils.js';
import EquipmentEvents from '../equipment/EquipmentEvents.js';

export default class EventsDomain {
  
  constructor(domain, storage) {
    this.domain = domain;
    this.logger = this.domain.logger();
    
    this.storage = storage;
    
    this._address = new AddressEvents(this.domain);
    this._company = new CompanyEvents(this);
    this._employee = new EmployeeEvents(this);
    this._user = new UserEvents(this);
    this._credentials = new CredentialEvents(this.domain);
    this._client = new ClientEvents(this);
    this._vehicle = new VehicleEvents(this);
    this._inspection = new InspectionEvents(this);
    this._invoice = new InvoiceEvents(this);
    this._timesheet = new TimesheetEvents(this);
    this._app = new AppEvents(this);
    this._checkIn = new CheckInEvents(this);
    this._schedule = new ScheduleEvents(this);
    this._cards = new CardEvents(this);
    this._checklist = new CheckListEvents(this);
    this._equipment = new EquipmentEvents(this);

    this._events = [
                    this._address,
                    this._company,
                    this._employee,
                    this._user,
                    this._credentials,
                    this._client,
                    this._vehicle,
                    this._inspection,
                    this._invoice,
                    this._timesheet,
                    this._app,
                    this._checkIn,
                    this._schedule,
                    this._checklist,
                    this._equipment,
                    ];
  }
  
  findByData(data) {
    var type = data[ConstUtils.FIELDS.CLASS];
    for (var i = 0; i < this._events.length; i++) {
      var events = this._events[i];
      if (events.contains(type)) {
        return events.findByName(type, data);
      }
    }
    return null;
  }
  
  addListener(eventName, listener, context) {
    if (eventName && listener) {
      var wrapper = new RcEventListener(this.domain, listener, context);
      this._addListener({ eventName: eventName, listener: wrapper });
    }
  }
  
  removeListener(eventName) {
    if (eventName) {
      this._removeListener({eventName});
    }
  }
  
  withListeners(addListener, removeListener) {
    this._addListener = addListener;
    this._removeListener = removeListener;
  }
 
  app() {
    return this._app;
  }
  
  checkins() {
    return this._checkIn;
  }
  
  checklists() {
    return this._checklist;
  }
  
  equipment() {
    return this._equipment;
  }

  timesheets() {
    return this._timesheet;
  }
  
  invoice() {
    return this._invoice;
  }
  
  cards() {
    return this._cards;
  }
  
  schedules() {
    return this._schedule;
  }
  
  company() {
    return this._company;
  }
  
  companys() {
    return this._company;
  }

  client() {
    return this._client;
  }
  
  inspections() {
    return this._inspection;
  }
  
  vehicles() {
    return this._vehicle;
  }
  
  users() {
    return this._user;
  }
  
  employees() {
    return this._employee;
  }
  
  initRequest(event) {
    var user = null;
    var userId = uuid.v1();
    var session = this.domain.session();
    if (session.user && !session.user().isNew()) {
      user = session.user().data;
      userId = session.user().id();
    }
    
    var company = session.company();
    if (company && !event[Company.MODEL_NAME]) {
      var companyId = session.company().id();
      event['Company'] = companyId;
    }
    
    var employee = session.employee();
    if (employee && !employee.isNew()) {
      var employeeId = employee.id();
      event['Employee'] = employeeId;
    }
    
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    event['timeZone'] = tz;
    
    event['maker'] = window.navigator.userAgent;
    
    var client = this.domain.client();
    var deviceId = client.deviceId;
    var version = client.version;
    
    EventsDomain.initEvent(event, user, userId, deviceId, version);
    
    var rcEvent = new RcRequestEvent(this.domain, event);
    return rcEvent;
  }
  
  static initEvent(event, user, userId, deviceId, version) {
    if (user) {
      event['authUser'] = user;
    }
    event['authUserId'] = userId;
    event['@rid'] = uuid.v1();
    event['deviceId'] = deviceId;
    event['clientVersion'] = version;
    event['client'] = 'client_web';
  }
  
  addressSave(address) {
    var company = this.domain.session().company();
    var event = AddressEvents.Save.Request(company, address);
    return this.initRequest(event);
  }
  
  addressList(after, page) {
    var company = this.domain.session().company();
    var event = AddressEvents.List.Request(company, after, page);
    return this.initRequest(event);
  }
  
  employeeList(company, after, page) {
    var event = EmployeeEvents.List.Request(company, after, page);
    return this.initRequest(event);
  }
  
  employeeAdd(employeeData) {
    var company = this.domain.session().company();
    employeeData['Company'] = company.id();
    var event = EmployeeEvents.Add.Request(employeeData);
    return this.initRequest(event);
  }

  employeeRemove(employee) {
    var event = EmployeeEvents.Remove.Request(employee);
    return this.initRequest(event);
  }
  
  inspectionDetails(data) {
    var event = InspectionEvents.Details.Request(data);
    return this.initRequest(event);
  }
  
  vehicleSave(vehicle) {
    var company = this.domain.session().company();
    var event = VehicleEvents.Save.Request(company, vehicle);
    return this.initRequest(event);
  }
  
  vehicleList(company, after) {
    var event = VehicleEvents.List.Request(company, after);
    return this.initRequest(event);
  }
  
  vehicleRemove(vehicle) {
    var event = VehicleEvents.Remove.Request(vehicle);
    return this.initRequest(event);
  }
  
  companyList(after, page) {
    var company = this.domain.session().company();
    var event = CompanyEvents.List.Request(company, after, page);
    return this.initRequest(event);
  }

  companySave(data) {
    var event = CompanyEvents.Save.Request(data);
    return this.initRequest(event);
  }
  
  credentialsSave(oldPassword, newPassword) {
    var event = CredentialEvents.Save.Request(oldPassword, newPassword);
    return this.initRequest(event);
  }
  
  userSave(user, employee) {
    var event = UserEvents.Save.Request(user, employee);
    return this.initRequest(event);
  }
  
  userSignOut() {
    var event = UserEvents.SignOut.Request();
    return this.initRequest(event);
  }
  
  userSignIn(email, password) {
    var event = UserEvents.SignIn.Request(email, password);
    return this.initRequest(event);
  }

  userSignUp(kvps) {
    var event = UserEvents.SignUp.Request(kvps);
    return this.initRequest(event);
  }

  userInvite(data) {
    var event = UserEvents.Invite.Request(data);
    return this.initRequest(event);
  }
  
  employeeResend(user) {
    var company = this.domain.session().company();
    var event = EmployeeEvents.Resend.Request(company, user);
    return this.initRequest(event);
  }
  
  adminUserSignUp(data) {
    var event = AdminEvents.SignUp.Request(data);
    return this.initRequest(event);
  }
    
}