
//import ConstUtils from '@/utils/ConstUtils.js';
//import Inspection from '@/domain/model/inspection/Inspection.js';

export default class EquipmentEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter) {
    var event = EquipmentEvents.List.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  save(equipmentMap) {
    var event = EquipmentEvents.Save.Request(equipmentMap);
    return this.eventsDomain.initRequest(event);
  }

  static Save = {
    Names: {
      Request: 'EquipmentSaveRequestEvent',
      Response: 'EquipmentSaveResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(equipmentMap) {
      var event = {
        handler: EquipmentEvents.Save.Names.Request,
        eventName: EquipmentEvents.Save.Names.Request,
      }

      let params = {}
      params["EquipmentMap"] = equipmentMap.data;
      event["Params"] = params;

      return event;
    },
  }
  
  static List = {
    Names: {
      Request: 'EquipmentListRequestEvent',
      Response: 'EquipmentListResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(filter) {
      var event = {
        handler: EquipmentEvents.List.Names.Request,
        eventName: EquipmentEvents.List.Names.Request,
      }
      
      var params = {};
      params["filter"] = filter.data,
      event["Params"] = params;

      return event;
    },
  }
}